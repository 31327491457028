import axios from 'axios';
import Config from '../../config';

const getKeys = (text = '') => async (dispatch) => {
  const onSuccess = (response) => {
    dispatch({ type: 'keys', data: response.data });
    return response;
  };

  const onError = (error) => {
    console.log(error);
    return error;
  };

  try {
    dispatch({ type: 'keys', data: null });
    const response = await axios.post(`${Config.url}/api/keys/search`, {
      text,
    });
    onSuccess(response);
  } catch (error) {
    onError(error);
  }
};

export default getKeys;
