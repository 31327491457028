import React from 'react';
import {
  Accordion, AccordionDetails, AccordionSummary, Box,
  Container,
  makeStyles, Typography,
} from '@material-ui/core';
import Page from 'src/components/Page';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Alert } from '@material-ui/lab';
import Toolbar from './Toolbar';
import config from '../../../config';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const KeysView = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const data = [{
    path: 'Authentication: Bearer token',
    title: 'Authenticate via API Key',
    content: 'Generate a new API key. Use the following "Authorization" header: "Bearer API_KEY" on all POST API requests'
  }, {
    path: 'POST /api/vault/upload',
    title: 'Upload a new file',
    content: 'Upload a new file. Request must be sent as Content-Type "multipart/form-data". Fields: file (the file that you are uploading), description (the description of the file), tags (tags separated by comma), auth (true if private, false if public).'
  }, {
    path: 'POST /api/vault/sign',
    title: 'Create a private signature',
    content: 'Create a private file signature. Fields: shield (the shielded id of the file, returned from upload), maxAge (signature expires after maxAge milliseconds). Only to be used with private files. Also required with private files.',
  }, {
    path: 'POST /api/vault/search',
    title: 'Search files',
    content: 'Search through files. Fields: text (filter by partial filename), tags (array of tags), type (filter by file type), maxDate (ISODate upload), minDate (ISODate upload), minSize, maxSize, description',
  }, {
    path: 'GET /api/vault/:shield[?signature=SIGNATURE]',
    title: 'View a file',
    content: 'Use /api/vault/:shield to view a public file. Use the signature generated in /api/vault/sign to view a private file.',
  }, {
    path: 'POST /api/vault/delete',
    title: 'Delete file (trash or permanent)',
    content: 'Use /api/vault/delete to delete a file. Fields: shield (the shielded id of the file, returned from upload), permanent (boolean, whether it should go to garbage or be deleted permanently).',
  }, {
    path: 'POST /api/vault/restore',
    title: 'Restore file from trash',
    content: 'Use /api/vault/restore to restore a file from trash. Fields: shield (the shielded id of the file, returned from upload).',
  }];

  return (
    <Page
      className={classes.root}
      title="API Docs"
    >
      <Container maxWidth={false}>
        <Toolbar />
        <Box mt={3}>
          <Box pb={1}>
            <Typography variant="h3">
              Welcome to CloudOS!
            </Typography>
          </Box>
          <Box pb={3}>
            <Typography variant="body2">
              {/* eslint-disable-next-line max-len */}
              Currently, the Postman collection in the top right corner provides a much more extensive documentation of the API endpoints.
              <br />
              We will greatly enhance this section with future updates.
            </Typography>
          </Box>
          {config.demo && (
            <Box pb={3}>
              <Alert severity="warning">This is a demo and content will be reset periodically without warning. If you see illegal or harmful content here please email support@arpanet.com.tr urgently.</Alert>
            </Box>
          )}
          {data.map((e) => {
            const id = e.path.split('/').join('-');
            return (
              <Accordion
                expanded={expanded === id}
                onChange={handleChange(id)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`${id}-content`}
                  id={`${id}-header`}
                >
                  <Typography className={classes.heading}>{e.path}</Typography>
                  <Typography className={classes.secondaryHeading}>{e.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    {e.content}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Box>
      </Container>
    </Page>
  );
};

export default KeysView;
