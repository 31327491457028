import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import {
  Box, Button, TextField
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import DialogContentText from '@material-ui/core/DialogContentText';
import isEmpty from '../../../utils/isEmpty';
import updateFile from '../../../actions/vault/updateFile';

const EditDialog = ({ open, setOpen, shield = {} }) => {
  const dispatch = useDispatch();

  return (
    <Formik
      enableReinitialize
      initialValues={{
        description: shield.description,
        tags: (shield.tags || []).join(','),
        isPrivate: shield.auth,
        region: shield.region,
      }}
      onSubmit={(values, actions) => {
        dispatch(updateFile({
          shield: shield.shield,
          description: values.description,
          tags: values.tags,
          isPrivate: values.isPrivate,
          actions,
          setOpen
        }));
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        handleReset,
        isSubmitting,
        touched,
        values
      }) => (
        <Dialog open={open} onClose={() => setOpen(!open)} aria-labelledby="form-dialog-title" disableBackdropClick>
          <DialogTitle id="form-dialog-title">File details</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <b>Name:</b>
              {' '}
              {shield.name}
              <br />
              <b>Description:</b>
              {' '}
              {!isEmpty(shield.description) ? shield.description : 'n/a'}
              <br />
              <b>Type:</b>
              {' '}
              {shield.type}
              <br />
              <b>Tags:</b>
              {' '}
              {!isEmpty(shield.tags) ? shield.tags.join(', ') : 'n/a'}
              <br />
              <b>Region:</b>
              {' '}
              {shield.region || 'world'}
            </DialogContentText>
            <TextField
              error={Boolean(touched.description && errors.description)}
              helperText={errors.description || 'Description of the file (optional)'}
              margin="dense"
              name="description"
              label="New description"
              value={values.description}
              type="text"
              fullWidth
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <TextField
              error={Boolean(touched.tags && errors.tags)}
              helperText={errors.tags || 'Tags (separated by comma)'}
              margin="dense"
              name="tags"
              label="Tags"
              value={values.tags}
              type="text"
              fullWidth
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <Box>
              <FormControlLabel
                control={(
                  <Switch
                    checked={values.isPrivate}
                    onChange={handleChange}
                    name="isPrivate"
                  />
                )}
                label={values.isPrivate ? 'Private (requires authentication)' : 'Public (accessible without authentication)'}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                window.size = 0;
                handleReset();
                setOpen(!open);
              }}
              color="primary"
            >
              Close
            </Button>
            <Button onClick={handleSubmit} color="primary" disabled={isSubmitting && (values.description !== shield.description || values.tags !== (shield.tags || []).join(','))}>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};

EditDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  shield: PropTypes.object,
};

export default EditDialog;
