import axios from 'axios';
import Config from '../../config';
import capitalizeFirstLetter from '../../utils/capitlizeFirstLetter';

const editKey = ({
  id, title, actions, setOpen
}) => async (dispatch) => {
  actions.setSubmitting(true);

  const onSuccess = (response) => {
    const { result } = response.data;
    setOpen(false);
    dispatch({ type: 'keys-edited', key: result });
    dispatch({ type: 'snack', content: `key ${result.title} edited`, severity: 'success' });
    return response;
  };

  const onError = (error) => {
    if (error.response) {
      const { data } = error.response;
      if (data.title) actions.setFieldError('title', capitalizeFirstLetter(data.title));
    } else {
      dispatch({ type: 'snack', content: 'error while editing key', severity: 'error' });
    }
    return error;
  };

  try {
    const response = await axios.post(`${Config.url}/api/keys/edit`, {
      id, title,
    });
    onSuccess(response);
  } catch (error) {
    onError(error);
  }

  actions.setSubmitting(false);
};

export default editKey;
