import React, { useEffect } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import { useDispatch } from 'react-redux';
import { Alert } from '@material-ui/lab';
import getFiles from '../../../actions/vault/getFiles';
import Results from './Results';
import Toolbar from './Toolbar';
import config from '../../../config';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const VaultListView = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFiles());
  });

  return (
    <Page
      className={classes.root}
      title="Vault"
    >
      <Container maxWidth={false}>
        <Toolbar />
        <Box mt={3}>
          <Results />
        </Box>
        {config.demo && (
          <Box pt={3}>
            <Alert severity="warning">Please do not upload harmful or illegal content. This is a demo. If you delete an existing file, please upload a new one with the same content type for others to see. In case of issues, send an email to support@arpanet.com.tr.</Alert>
          </Box>
        )}
      </Container>
    </Page>
  );
};

export default VaultListView;
