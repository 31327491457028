import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import {
  Box, Button, CircularProgress, makeStyles, Typography
} from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

const useStyles = makeStyles(() => ({
  root: {},
  img: {
    maxWidth: '100%',
  },
  video: {
    maxWidth: '100%',
    background: 'black',
  },
  pdf: {
    maxWidth: '100%',
  },
  container: {
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
}));

const CircularProgressWithLabel = ({ value, ...props }) => {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
};

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

const PreviewDialog = ({
  open, setOpen, shield = {}, url,
}) => {
  const classes = useStyles();
  const video = useRef();
  const [pages, setPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (open) {
      setProgress(0);
      setPageNumber(1);
    }
  }, [open]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setPages(numPages);
  };

  return (
    <Dialog open={open} onClose={() => setOpen(!open)} aria-labelledby="form-dialog-title" disableBackdropClick>
      <DialogTitle id="form-dialog-title">File preview</DialogTitle>
      {shield.type && (
        <DialogContent>
          <DialogContentText>
            {`You are seeing the preview of "${shield.name}".`}
          </DialogContentText>
          {shield.type.includes('image') && (
            <Box className={classes.container}>
              <img src={url} alt={shield.name} className={classes.img} />
            </Box>
          )}
          {shield.type.includes('video') && (
            <Box className={classes.container}>
              {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
              <video src={url} className={classes.video} controls ref={video} autoPlay />
            </Box>
          )}
          {shield.type.includes('pdf') && url && (
            <Box className={classes.container}>
              <Document
                file={url}
                onLoadSuccess={onDocumentLoadSuccess}
                loading={<CircularProgressWithLabel value={progress} />}
                onLoadProgress={({ loaded, total }) => setProgress((loaded / total) * 100)}
              >
                <Page pageNumber={pageNumber} loading={<CircularProgress />} />
              </Document>
            </Box>
          )}
        </DialogContent>
      )}
      <DialogActions>
        {shield.type && shield.type.includes('pdf') && url && (
          <Button
            onClick={() => {
              setPageNumber(pageNumber - 1);
            }}
            color="primary"
            disabled={pageNumber === 1}
          >
            Previous
          </Button>
        )}
        {shield.type && shield.type.includes('pdf') && url && (
          <Button
            onClick={() => {
              setPageNumber(pageNumber + 1);
            }}
            color="primary"
            disabled={pageNumber === pages}
          >
            Next
          </Button>
        )}
        <Button
          onClick={() => {
            setOpen(!open);
            if (video && video.current) {
              video.current.pause();
            }
          }}
          color="primary"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

PreviewDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  shield: PropTypes.object,
  url: PropTypes.string,
};

export default PreviewDialog;
