import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Search as SearchIcon } from 'react-feather';
import { CSVLink, } from 'react-csv';
import exportData from '../../../utils/exportData';
import CreateDialog from './CreateDialog';
import getKeys from '../../../actions/keys/getKeys';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const Toolbar = ({ className, ...rest }) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const keys = useSelector((state) => state.keys.keys);

  const [createDialog, setCreateDialog] = useState(false);

  const csvData = [
    ['title', 'token', 'created'],
  ];

  if (keys) {
    keys.forEach((e) => {
      csvData.push([e.title, e.token, e.date]);
    });
  }

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box
        display="flex"
        justifyContent="flex-end"
      >
        <CSVLink data={csvData} filename="keys.csv">
          <Button className={classes.exportButton}>
            Export CSV
          </Button>
        </CSVLink>
        <Button className={classes.exportButton} onClick={() => exportData(keys)}>
          Export Data
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            setCreateDialog(true);
          }}
        >
          Add new key
        </Button>
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent style={{ paddingBottom: 16 }}>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Box width={500}>
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon
                          fontSize="small"
                          color="action"
                        >
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    )
                  }}
                  placeholder="Search keys"
                  variant="outlined"
                  onChange={(e) => dispatch(getKeys(e.target.value))}
                />
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
      <CreateDialog open={createDialog} setOpen={setCreateDialog} />
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
