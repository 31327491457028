import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Grid,
  Hidden,
  Link,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import Page from 'src/components/Page';
import { Alert } from '@material-ui/lab';
import login from '../../actions/auth/login';
import logo from '../../assets/cloudos-logo.png';
import config from '../../config';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const LoginView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Page
      className={classes.root}
      title="Login"
      style={{ minHeight: 462 + 120 }}
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              username: config.demo ? 'root' : '',
              password: config.demo ? 'root' : ''
            }}
            validationSchema={Yup.object().shape({
              username: Yup.string().max(255).required('Username is required'),
              password: Yup.string().max(255).required('Password is required')
            })}
            onSubmit={(values, actions) => {
              dispatch(login({
                username: values.username,
                password: values.password,
                navigate,
                actions
              }));
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box display="flex" justifyContent="center">
                  <img src={logo} alt="logo" style={{ width: 120, height: 120 }} />
                </Box>
                <Box mt={2} mb={1} display="flex" flexDirection="column" alignItems="center">
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    CloudOS
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Private Enterprise Cloud <strong>Object Storage</strong>
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.username && errors.username)}
                  fullWidth
                  helperText={touched.username && errors.username}
                  label="Username"
                  margin="normal"
                  name="username"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.username}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in now
                  </Button>
                </Box>

                {config.demo && (
                  <Box pt={3}>
                    <Alert severity="warning">This is a demo and content will be reset periodically without warning. If you see illegal or harmful content here please email support@arpanet.com.tr urgently.</Alert>
                  </Box>
                )}

                <Typography
                  color="textSecondary"
                  variant="h5"
                >
                  <Hidden smUp>
                    <Grid container direction="row">
                      <Grid item xs={12} container justify="center">
                        <Hidden xsUp>
                          <Grid item xs={12} container direction="row" justify="center">
                            <Link
                              component={RouterLink}
                              to="/auth/forgot"
                              variant="h6"
                            >
                              Forgot password
                            </Link>
                          </Grid>
                        </Hidden>
                        {config.registerEnabled && (
                          <Box mt={2}>
                            <Link
                              component={RouterLink}
                              to="/auth/register"
                              variant="h5"
                            >
                              Don&apos;t have an account? Sign up
                            </Link>
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Hidden>
                </Typography>

                <Typography
                  color="textSecondary"
                  variant="h6"
                >
                  <Hidden xsUp>
                    <Grid container direction="row">
                      <Grid item sm={12}>
                        {config.registerEnabled && (
                          <Box display="flex" justifyContent="center">
                            <Link
                              component={RouterLink}
                              to="/auth/register"
                              variant="h6"
                            >
                              Don&apos;t have an account? Sign up
                            </Link>
                          </Box>
                        )}
                      </Grid>
                      <Hidden xsUp>
                        <Grid item sm={5} container direction="row" justify="flex-end" mb={5}>
                          <Link
                            component={RouterLink}
                            to="/auth/forgot"
                            variant="h6"
                          >
                            Forgot password
                          </Link>
                        </Grid>
                      </Hidden>
                    </Grid>
                  </Hidden>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
