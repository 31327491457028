import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Avatar, Box, Card, CardContent, makeStyles, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import gravatar from 'gravatar';

const useStyles = makeStyles(() => ({
  root: {}, avatar: {
    height: 100, width: 100
  }
}));

const Profile = ({ className, ...rest }) => {
  const classes = useStyles();
  const user = useSelector((state) => state.auth.user);

  return (<Card
    className={clsx(classes.root, className)}
    {...rest}
  >
    <CardContent>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
      >
        <Avatar
          className={classes.avatar}
          src={gravatar.url(user.email, { d: 'mp', s: 200 })}
        />
        <Typography
          color="textPrimary"
          gutterBottom
          variant="h4"
        >
          {`${user.name}`}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body1"
        >
          {`@${user.username}`}
        </Typography>
        <Typography
          className={classes.dateText}
          color="textSecondary"
          variant="body1"
          gutterBottom
        >
          {`${user.email}`}
        </Typography>
        <Typography
          className={classes.dateText}
          color="textSecondary"
          variant="body1"
        >
          Roles:
          {' '}
          {user.roles.join(', ')}
        </Typography>
      </Box>
    </CardContent>
  </Card>);
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
