import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
  colors, CircularProgress
} from '@material-ui/core';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.indigo[600],
    height: 56,
    width: 56
  }
}));

const TotalFiles = ({ className, ...rest }) => {
  const classes = useStyles();
  const files = useSelector((state) => state.stats.files);

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent style={{ paddingBottom: 16 }}>
        <Grid
          container
          justify="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              VAULT SIZE
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
            >
              {files ? `${files.total} files` : <CircularProgress size={24} />}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <AccountTreeIcon />
            </Avatar>
          </Grid>
        </Grid>

        <Typography
          color="textPrimary"
          variant="h6"
        >
          {files
            ? `+${files.additions} files this month`
            : <span>&nbsp;</span>}
        </Typography>
        <Typography
          color="textPrimary"
          variant="h6"
        >
          {files
            ? `-${files.removals} files this month`
            : <span>&nbsp;</span>}
        </Typography>
      </CardContent>
    </Card>
  );
};

TotalFiles.propTypes = {
  className: PropTypes.string
};

export default TotalFiles;
