import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles, Popover, Typography, CardContent, Card, useTheme
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import moment from 'moment';
import Logo from 'src/components/Logo';
import logout from '../../actions/auth/logout';
import getUpdates from '../../actions/getUpdates';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(2),
    paddingBottom: 0,
  },
  avatar: {
    width: 60,
    height: 60
  },
  typography: {
    padding: theme.spacing(2),
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const theme = useTheme();
  const classes = useStyles();
  const [notifications] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const updates = useSelector((state) => state.updates.data || []);

  useEffect(() => {
    dispatch(getUpdates());
  }, []);

  const exit = () => dispatch(logout({ navigate }));
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar>
        <RouterLink to="/">
          <Logo/>
        </RouterLink>
        <Box flexGrow={1}/>
        {updates.length > 0 && <React.Fragment>
          <IconButton color="inherit" onClick={handleClick}>
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon/>
            </Badge>
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            PaperProps={{
              style: {
                width: 500,
                background: theme.palette.background.dark,
              },
            }}
          >
            {updates.map((e) => (
              <Card className={classes.card}>
                <CardContent style={{ paddingBottom: 16 }}>
                  <Typography color="textSecondary">
                    {e.title}
                  </Typography>
                  <Typography variant="body2">
                    {e.content}
                  </Typography>
                  <Typography variant="caption">
                    {moment(e.timestamp).format('D MMMM YYYY')}
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </Popover>
        </React.Fragment>}
        <Hidden mdDown>
          <IconButton onClick={exit} color="inherit">
            <InputIcon/>
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <MenuIcon/>
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
