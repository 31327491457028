const initialState = {
  files: null,
  garbage: false,
};

const reducer = (state = initialState, action) => {
  let files = [...(state.files || [])];
  let index = 0;
  switch (action.type) {
    case 'garbage':
      return {
        ...state,
        garbage: action.value,
      };
    case 'files':
      return {
        ...state,
        files: (action.data || {}).shields,
      };
    case 'vault-deleted':
      files.splice(files.findIndex((e) => e.shield === action.shield.shield), 1);
      return {
        ...state,
        files,
      };
    case 'vault-deleted-many':
      for (let i = 0; i < action.files.length; i++) {
        files.splice(files.findIndex((e) => e.shield === action.files[i].shield), 1);
      }
      return {
        ...state,
        files,
      };
    case 'vault-uploaded':
      files = [action.shield, ...files];
      return {
        ...state,
        files,
      };
    case 'vault-updated':
      index = files.findIndex((e) => e.shield === action.shield.shield);
      if (index < 0) {
        return state;
      }
      files[index] = action.shield;
      return {
        ...state,
        files,
      };
    default:
      return state;
  }
};

export default reducer;
