import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import AccountView from 'src/views/account/AccountView';
import UsersListView from 'src/views/users/UsersListView';
import DashboardView from 'src/views/reports/DashboardView';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import DocsView from 'src/views/api/DocsView';
import KeysView from 'src/views/api/KeysView';
import VaultListView from 'src/views/vault/VaultListView';

const routes = [
  {
    path: '',
    element: <DashboardLayout />,
    children: [
      { path: '', element: <Navigate to="/dashboard" /> },
      { path: 'account', element: <AccountView /> },
      { path: 'vault', element: <VaultListView /> },
      { path: 'users', element: <UsersListView /> },
      { path: 'dashboard', element: <DashboardView /> },
      { path: 'docs', element: <DocsView /> },
      { path: 'keys', element: <KeysView /> },
    ]
  },
  {
    path: '/auth',
    element: <MainLayout />,
    children: [
      { path: '', element: <Navigate to="/auth/login" /> },
      { path: 'login', element: <LoginView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/404',
    element: <MainLayout />,
    children: [
      { path: '', element: <NotFoundView /> },
    ]
  },
  {
    path: '/login',
    element: <MainLayout />,
    children: [
      { path: '', element: <Navigate to="/auth/login" /> },
    ]
  },
  { path: '*', element: <Navigate to="/404" /> }
];

export default routes;
