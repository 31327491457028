import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Box, Button, Typography } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import deleteFiles from '../../../actions/vault/deleteFiles';
import formatBytes from '../../../utils/formatBytes';

const DeleteManyDialog = ({ open, setOpen, files }) => {
  const dispatch = useDispatch();
  const [permanent, setPermanent] = useState(true);

  let size = 0;

  for (let i = 0; i < files.length; i++) {
    size += files[i].size;
  }

  if (!files[0]) {
    files = [{}];
  }

  return (
    <Formik
      initialValues={{}}
      onSubmit={(values, actions) => {
        dispatch(deleteFiles({
          shields: files.map((e) => e.shield),
          permanent: permanent || files[0].garbage,
          actions,
          setOpen
        }));
      }}
    >
      {({
        handleSubmit,
        isSubmitting
      }) => (
        <Dialog open={open} onClose={() => setOpen(!open)} aria-labelledby="form-dialog-title" disableBackdropClick>
          <DialogTitle id="form-dialog-title">Delete files</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {`Do you really want to delete the selected ${files.length} files?`}
            </DialogContentText>
            {!files[0].garbage && (
              <Box>
                <FormControlLabel
                  control={<Switch checked={permanent} onChange={(e) => setPermanent(e.target.checked)} name="gilad" />}
                  label="Delete permanently"
                />
              </Box>
            )}
            {(permanent || files[0].garbage) && <Typography variant="body2">{`${formatBytes(size)} will be freed`}</Typography>}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(!open)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSubmit} color="primary" disabled={isSubmitting}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};

DeleteManyDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  files: PropTypes.array
};

export default DeleteManyDialog;
