import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import deleteKeys from '../../../actions/keys/deleteKeys';

const DeleteManyDialog = ({ open, setOpen, keys }) => {
  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{}}
      onSubmit={(values, actions) => {
        dispatch(deleteKeys({
          ids: keys.map((e) => e.id),
          actions,
          setOpen
        }));
      }}
    >
      {({
        handleSubmit,
        isSubmitting
      }) => (
        <Dialog open={open} onClose={() => setOpen(!open)} aria-labelledby="form-dialog-title" disableBackdropClick>
          <DialogTitle id="form-dialog-title">Delete users</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {`Do you really want to delete users ${keys.map((e) => `${e.title}`).join(', ')}?`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(!open)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSubmit} color="primary" disabled={isSubmitting}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};

DeleteManyDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  keys: PropTypes.array
};

export default DeleteManyDialog;
