import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Button, Grid, CircularProgress, Tooltip
} from '@material-ui/core';
import {
  Trash as TrashIcon,
  HardDrive as HardDriveIcon,
  ZoomIn as ZoomInIcon,
  Download as DownloadIcon,
  RotateCcw as RotateCcwIcon,
  Clipboard as ClipboardIcon
} from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import Config from '../../../config';
import DeleteDialog from './DeleteDialog';
import EditDialog from './EditDialog';
import PreviewDialog from './PreviewDialog';
import restoreFile from '../../../actions/vault/restoreFile';
import DeleteManyDialog from './DeleteManyDialog';
import CopyDialog from './CopyDialog';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

function bytesToSize(bytes) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
  if (!bytes) return '0 Byte';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  return `${Math.round((bytes / 1024 ** i) * 10, 2) / 10} ${sizes[i]}`;
}

const Results = ({ className, ...rest }) => {
  const classes = useStyles();
  const [selectedFileIds, setSelectedFileIds] = useState([]);
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(0);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteManyDialog, setDeleteManyDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [previewDialog, setPreviewDialog] = useState(false);
  const [copyDialog, setCopyDialog] = useState(false);
  const [url, setUrl] = useState(null);
  const [selectedShield, setSelectedShield] = useState({});
  const dispatch = useDispatch();
  const files = useSelector((state) => state.vault.files || []);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    setSelectedFileIds([]);
  }, [files]);

  const selectedShields = files.filter((e) => selectedFileIds.includes(e.shield)) || [];

  const isRootUser = (user.roles || []).includes('root');

  const currentLength = files.slice(page * limit, page * limit + limit).length;

  if (!files) {
    return (
      <Grid container justify="center" alignItems="center" style={{ height: '100%' }}>
        <CircularProgress />
      </Grid>
    );
  }

  if (files.length === 0) {
    return (
      <Grid container justify="center" alignItems="center" style={{ height: '100%' }}>
        <Typography variant="body1">
          No files to show for the selected filters.
        </Typography>
      </Grid>
    );
  }

  const handleSelectAll = () => {
    let newSelectedFileIds;

    if (selectedFileIds.length !== currentLength) {
      // eslint-disable-next-line max-len
      newSelectedFileIds = files.slice(page * limit, page * limit + limit).map((file) => file.shield);
    } else {
      newSelectedFileIds = [];
    }

    setSelectedFileIds(newSelectedFileIds);
  };

  const handleSelectOne = (event, id) => {
    if (selectedFileIds.includes(id)) {
      const selection = [...selectedFileIds];
      selection.splice(selection.indexOf(id), 1);
      setSelectedFileIds(selection);
    } else {
      setSelectedFileIds([...selectedFileIds, id]);
    }
  };

  const handleLimitChange = (event) => {
    setSelectedFileIds([]);
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setSelectedFileIds([]);
    setPage(newPage);
  };

  const download = async (file) => {
    // eslint-disable-next-line no-shadow
    let url = `${Config.url}/api/vault/${file.shield}.${file.extension}`;
    if (file.auth) {
      try {
        const result = await axios.post(`${Config.url}/api/vault/sign`, { shield: file.shield });
        const parts = (result.data.url || '').split('?');
        url = `${parts[0]}.${file.extension}${parts[1] ? `?${parts[1]}` : ''}`;
      } catch (e) {
        dispatch({ type: 'snack', content: 'download failed: error while generating url', severity: 'error' });
        return;
      }
    }
    dispatch({ type: 'snack', content: `download started: ${file.name}`, severity: 'info' });
    const link = document.createElement('a');
    link.href = `${url}?action=download`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const preview = async (file) => {
    // eslint-disable-next-line no-shadow
    let url = `${Config.url}/api/vault/${file.shield}.${file.extension}`;
    if (file.auth) {
      try {
        const result = await axios.post(`${Config.url}/api/vault/sign`, { shield: file.shield });
        const parts = (result.data.url || '').split('?');
        url = `${parts[0]}.${file.extension}${parts[1] ? `?${parts[1]}` : ''}`;
        setUrl(url);
        setSelectedShield(file);
        setPreviewDialog(true);
      } catch (e) {
        dispatch({ type: 'snack', content: 'preview failed: error while generating preview', severity: 'error' });
      }
    } else {
      setUrl(url);
      setSelectedShield(file);
      setPreviewDialog(true);
    }
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <PerfectScrollbar>
        <Box minWidth={1144}>
          <Table style={{ overflowX: 'scroll' }}>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={
                      selectedFileIds.length === currentLength
                      && selectedFileIds.length > 1
                    }
                    color="primary"
                    indeterminate={
                      selectedFileIds.length > 0
                      && selectedFileIds.length < currentLength
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell>
                  Name
                </TableCell>
                <TableCell>
                  Quick Actions
                </TableCell>
                {isRootUser && (
                  <TableCell>
                    Owner
                  </TableCell>
                )}
                <TableCell>
                  Uploaded
                </TableCell>
                <TableCell>
                  Size
                </TableCell>
                <TableCell>
                  Tags
                </TableCell>
                <TableCell>
                  Region
                </TableCell>
                <TableCell>
                  Visibility
                </TableCell>
                <TableCell>
                  Views
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {files.slice(page * limit, page * limit + limit).map((file) => (
                <TableRow
                  hover
                  key={file.shield}
                  selected={selectedFileIds.includes(file.shield)}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedFileIds.includes(file.shield)}
                      onChange={(event) => handleSelectOne(event, file.shield)}
                      value="true"
                    />
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="body2"
                    >
                      {file.name.length > 24 ? (
                        <span>
                          {file.name.substr(0, 9)}
                          {' ... '}
                          {file.name.substr(file.name.length - 10, 10)}
                        </span>
                      ) : file.name}
                    </Typography>
                  </TableCell>
                  <TableCell width={file.garbage ? 170 : 382}>
                    {!file.garbage && (
                      <Tooltip title="Copy URL to Clipboard" arrow placement="top">
                        <Button
                          color="primary"
                          variant="contained"
                          style={{ height: 36, marginRight: 6 }}
                          onClick={() => {
                            setSelectedShield(file);
                            setCopyDialog(true);
                          }}
                        >
                          <ClipboardIcon size={16} />
                        </Button>
                      </Tooltip>
                    )}
                    {!file.garbage && (
                      <Tooltip title="Preview" arrow placement="top">
                        <Button
                          color="primary"
                          variant="contained"
                          style={{ height: 36, marginRight: 6 }}
                          disabled={!file.type.includes('image') && !file.type.includes('video') && !file.type.includes('pdf')}
                          onClick={() => preview(file)}
                        >
                          <ZoomInIcon size={16} />
                        </Button>
                      </Tooltip>
                    )}
                    {!file.garbage && (
                      <Tooltip title="Download" arrow placement="top">
                        <Button
                          color="primary"
                          variant="contained"
                          style={{ height: 36, marginRight: 6 }}
                          onClick={() => download(file)}
                        >
                          <DownloadIcon size={16} />
                        </Button>
                      </Tooltip>
                    )}
                    {!file.garbage && (
                      <Tooltip title="View & edit details" arrow placement="top">
                        <Button
                          color="primary"
                          variant="contained"
                          style={{ height: 36, marginRight: 6 }}
                          onClick={() => {
                            setSelectedShield(file);
                            setEditDialog(true);
                          }}
                        >
                          <HardDriveIcon size={16} />
                        </Button>
                      </Tooltip>
                    )}
                    {file.garbage && (
                      <Tooltip title="Restore" arrow placement="top">
                        <Button
                          color="primary"
                          variant="contained"
                          style={{ height: 36, marginRight: 6 }}
                          onClick={() => dispatch(restoreFile({ shield: file.shield }))}
                        >
                          <RotateCcwIcon size={16} />
                        </Button>
                      </Tooltip>
                    )}
                    <Tooltip title="Delete" arrow placement="top">
                      <Button
                        color="primary"
                        variant="contained"
                        style={{ height: 36 }}
                        onClick={() => {
                          setSelectedShield(file);
                          setDeleteDialog(true);
                        }}
                      >
                        <TrashIcon size={16} />
                      </Button>
                    </Tooltip>
                  </TableCell>
                  {isRootUser && (
                    <TableCell>
                      {file.ownerDetails ? `@${file.ownerDetails.username}` : '[deleted]'}
                    </TableCell>
                  )}
                  <TableCell>
                    {moment(file.timestamp).fromNow()}
                  </TableCell>
                  <TableCell>
                    {bytesToSize(file.size)}
                  </TableCell>
                  <TableCell>
                    {(file.tags || []).length > 0 ? (file.tags || []).join(', ') : 'n/a'}
                  </TableCell>
                  <TableCell>
                    {file.region || 'world'}
                  </TableCell>
                  <TableCell>
                    {file.auth ? 'Private' : 'Public'}
                  </TableCell>
                  <TableCell>
                    {file.views}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <Box display="flex" flexDirection="row" alignItems="center">
        {selectedFileIds.length > 0 && (
          <Box ml={2}>
            <Button
              color="primary"
              variant="contained"
              size="small"
              onClick={() => setDeleteManyDialog(true)}
            >
              Delete
              {' '}
              {`(${selectedFileIds.length})`}
            </Button>
          </Box>
        )}
        <Box flexGrow={1}>
          <TablePagination
            component="div"
            count={files.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </Box>
      </Box>
      <DeleteDialog open={deleteDialog} setOpen={setDeleteDialog} shield={selectedShield} />
      {/* eslint-disable-next-line max-len */}
      <DeleteManyDialog open={deleteManyDialog} setOpen={setDeleteManyDialog} files={selectedShields} />
      <EditDialog open={editDialog} setOpen={setEditDialog} shield={selectedShield} />
      <CopyDialog open={copyDialog} setOpen={setCopyDialog} shield={selectedShield} />
      <PreviewDialog
        open={previewDialog}
        setOpen={setPreviewDialog}
        url={url}
        shield={selectedShield}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string
};

export default Results;
