const initialState = {
  users: null,
};

const reducer = (state = initialState, action) => {
  let users = [...(state.users || [])];
  let index = 0;
  switch (action.type) {
    case 'users':
      return {
        ...state,
        users: (action.data || {}).users,
      };
    case 'users-deleted':
      users.splice(users.findIndex((e) => e.id === action.user.id), 1);
      return {
        ...state,
        users,
      };
    case 'users-deleted-many':
      for (let i = 0; i < action.users.length; i++) {
        users.splice(users.findIndex((e) => e.id === action.users[i].id), 1);
      }
      return {
        ...state,
        users,
      };
    case 'users-created':
      users = [action.user, ...users];
      return {
        ...state,
        users,
      };
    case 'users-edited':
      index = users.findIndex((e) => e.id === action.user.id);
      if (index < 0) {
        return state;
      }
      users[index] = action.user;
      return {
        ...state,
        users,
      };
    default:
      return state;
  }
};

export default reducer;
